/*
  Sends requests to Skiff Flask service that wraps
  the S2 Public API to allow us to securely pass API keys.

  We can call S2's public API directly from the browser JS runtime,
  but we are subject to unauthenticated rate limiting rules unless
  we provide an API key, which grants us higher usage. We do not want
  the JS runtime to know the API key for security reasons.
 */

import axios from "axios";

const SEARCH_LIMIT = 5;

export interface Paper {
  paperId: string;
  title: string;
}

const client = axios.create({
  baseURL: "/api"
})

function getPaperData(paperId: string): Promise<Paper> {
  return client.get(`/paper/${paperId}`).then((resp) => resp.data)
}

function searchPapers(searchQuery: string): Promise<Paper[]> {
  return client.get(
    "/paper/search",
    { params: { query: searchQuery, limit: SEARCH_LIMIT } }
  ).then((resp) => {
    return resp.data.data;
  });
}

function findSimilarRecentPapers(paperId: string): Promise<Paper[]> {
  return client.get(`/paper/${paperId}/recommendations`)
    .then((resp) => resp.data.recommendedPapers);
}

export default {
  findSimilarRecentPapers,
  getPaperData,
  searchPapers
};
