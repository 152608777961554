import React, { useContext, useEffect, useState } from "react";

import { FolderWithPapers, LibraryFoldersContext } from "./WithLibraryFoldersContext";
import { PaperSearchResult } from "./PaperSearchResult";
import S2PublicApiProxy, { Paper } from "../api/S2PublicApiProxy";
import {PaperLink} from "./PaperLink";

/* A widget that samples a random paper from your library and then lets you
 * use the S2 Public API to find similar papers.
 * Which you can of course then add to your library!
 */
export const SimilarPapersLookupWidget = () => {
  const [isSampling, setIsSampling] = useState(false);
  const [sampledPaper, setSampledPaper] = useState<Paper | undefined>();
  const [isQuerying, setIsQuerying] = useState(false);
  const [queryResults, setQueryResults] = useState<Paper[]>([])
  const { libraryFolders } = useContext(LibraryFoldersContext)

  const samplePaperFromLibrary = () => {
    setQueryResults([]);
    setIsSampling(true);

    const allPapersInLibrary: string[] = [];
    libraryFolders.forEach((folder: FolderWithPapers) => {
      folder.paperIds.forEach((paperId) => allPapersInLibrary.push(paperId));
    });
    const sampledId = allPapersInLibrary[Math.floor(Math.random() * allPapersInLibrary.length)];

    S2PublicApiProxy.getPaperData(sampledId)
      .then((paper: Paper) => {
        setSampledPaper(paper);
        setIsSampling(false);
      });
  };

  const findSimilarPapers = () => {
    setIsQuerying(true);

    if (sampledPaper) {
      S2PublicApiProxy.findSimilarRecentPapers(sampledPaper.paperId)
        .then(setQueryResults)
        .finally(() => setIsQuerying(false));
    } else {
      setIsQuerying(false);
    }
  };

  const mkKey = (pid1: string, pid2: string) => `${pid1}-sim-${pid2}`;

  useEffect(() => {
    samplePaperFromLibrary();
  }, []);

  return (
    <section id="similarPapers">
      <h2>Similar Paper Lookup (Widget 3)</h2>
      {!isSampling && sampledPaper !== undefined ? (
        <div>
          <header>Here's a random paper from your library:</header>
          <div>
            <PaperLink paper={sampledPaper} />
            <button onClick={samplePaperFromLibrary} disabled={isQuerying}>Resample</button>
            <button onClick={findSimilarPapers} disabled={isQuerying}>Find Similar Papers</button>
          </div>
          <div>
            {isQuerying ? (
              <span>Looking up similar papers, hold your horses</span>
            ) : (
              <ul>
                {queryResults.map((paper) => {
                  return (
                    <li key={mkKey(sampledPaper.paperId, paper.paperId)}>
                      <PaperSearchResult paper={paper} />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      ) : (
        <div>Currently sampling please wait...</div>
      )}
    </section>
  );
};
