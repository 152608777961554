import React, { useContext, useState } from "react";

import { FolderWithPapers, LibraryFoldersContext } from "./WithLibraryFoldersContext";
import { Paper } from "../api/S2PublicApiProxy";
import { PaperLink } from "./PaperLink";

export const PaperSearchResult = ({ paper }: { paper: Paper }) => {
  const { libraryFolders } = useContext(LibraryFoldersContext);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleButtonClick = () => setIsExpanded(!isExpanded);

  const countFoldersForPaper = (paper: Paper) => {
    let count = 0;

    libraryFolders.forEach((folder: FolderWithPapers) => {
      if (folder.paperIds.has(paper.paperId)) {
        count++;
      }
    });

    return count;
  }

  const buttonText = isExpanded
    ? "Collapse"
    : `Manage Folders (in ${countFoldersForPaper(paper)})`;

  return (
    <div>
      <PaperLink paper={paper} />
      <div>
        <button onClick={handleButtonClick}>{buttonText}</button>
        {isExpanded ? (
          <ul>
            {libraryFolders.map((folder) => {
              return <li key={`${paper.paperId}-${folder.id}`}><AddRemovePaperFromFolderButton folder={folder} paper={paper} /></li>;
            })}
          </ul>
        ) : null}
      </div>
    </div>
  )
};

export const AddRemovePaperFromFolderButton = ({ folder, paper }: { folder: FolderWithPapers, paper: Paper }) => {
  const [isAddingOrRemoving, setIsAddingOrRemoving] = useState(false);
  const { addPaperToFolder, removePaperFromFolder } = useContext(LibraryFoldersContext);

  const paperInFolder = () => folder.paperIds.has(paper.paperId);
  const handleClick = () => {
    setIsAddingOrRemoving(true);

    const promise = (
      paperInFolder()
        ? removePaperFromFolder(folder.id, paper.paperId)
        : addPaperToFolder(folder.id, paper.paperId)
    );

    promise.finally(() => setIsAddingOrRemoving(false));
  };

  return (
    <React.Fragment>
      <button onClick={handleClick} disabled={isAddingOrRemoving}>{paperInFolder() ? "Remove" : "Add" }</button>
      <span>{folder.name}</span>
    </React.Fragment>
  );
}
