import axios from "axios";

const S2_USER_URL = "https://www.semanticscholar.org/api/1/user";
const S2_LIBRARY_URL = "https://www.semanticscholar.org/api/skiff/library";

function isLoggedIn(): Promise<boolean> {
  return axios.get(S2_USER_URL, { withCredentials: true })
    .then((_) => true)
    .catch((_) => false);
}

const libraryClient = axios.create({
  withCredentials: true,
  baseURL: S2_LIBRARY_URL
});

export interface FoldersResponse {
  folders: Folder[];
}

export interface Folder {
  id: number;
  name: string;
}

export interface FolderPapersResponse {
  folderId: number;
  paperIds: string[];
}

function getLibraryFolders(): Promise<FoldersResponse> {
  return libraryClient.get("/folders").then((resp) => resp.data);
}

function getLibraryFolderPapers(folderId: number): Promise<FolderPapersResponse> {
  return libraryClient.get(`/folders/${folderId}/papers`).then((resp) => resp.data);
}

function addPapersToLibraryFolder(folderId: number, paperIds: string[]): Promise<FolderPapersResponse> {
  return libraryClient.post(`/folders/${folderId}/papers`, { paperIds }).then((resp) => resp.data);
}

function removePaperFromLibraryFolder(folderId: number, paperId: string): Promise<string> {
  return libraryClient.delete(`/folders/${folderId}/papers/${paperId}`).then((resp) => resp.data.msg);
}

export default {
  addPapersToLibraryFolder,
  getLibraryFolderPapers,
  getLibraryFolders,
  isLoggedIn,
  removePaperFromLibraryFolder,
};
