import React, { useState } from "react";

import S2PublicApiProxy, { Paper } from "../api/S2PublicApiProxy";
import { PaperSearchResult } from "./PaperSearchResult";

/* A widget that lets you search for papers with the S2 Public API and add them to your Library
 * Requires LibraryFoldersContext
 */
export const LibraryPaperSearchWidget = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<Paper[]>([]);

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);
  const maybeSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsSearching(true);
      S2PublicApiProxy.searchPapers(searchValue)
        .then(setSearchResults)
        .finally(() => setIsSearching(false));
    }
  };

  return (
    <section id="searchWidget">
      <h2>Paper Search (Widget 2)</h2>
      <div>
        <input
          onChange={onSearchValueChange} placeholder="Search for papers..."
          onKeyPress={maybeSearch}
          disabled={isSearching}
        />
      </div>
      <ul>
        {searchResults.map((paper: Paper) => {
          return (
            <li key={paper.paperId}>
              <PaperSearchResult paper={paper} />
            </li>
          );
        })}
      </ul>
    </section>
  );
};
