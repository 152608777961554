import React from "react";

import { LibraryFolderListWidget } from "../components/LibraryFolderListWidget";
import { LibraryPaperSearchWidget } from "../components/LibraryPaperSearchWidget";
import { S2LoginGate } from "../components/S2LoginGate";
import { TwoColumnGrid } from "../components/shared";
import { WithLibraryFoldersContext } from "../components/WithLibraryFoldersContext";
import {SimilarPapersLookupWidget} from "../components/SimilarPapersLookupWidget";

export const Home = () => {
  return (
    <S2LoginGate>
      <WithLibraryFoldersContext>
        <TwoColumnGrid>
          <LibraryFolderListWidget/>
          <LibraryPaperSearchWidget/>
          <SimilarPapersLookupWidget />
        </TwoColumnGrid>
      </WithLibraryFoldersContext>
    </S2LoginGate>
  );
}

