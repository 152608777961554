import React, { useContext } from "react";

import { LibraryFoldersContext } from "./WithLibraryFoldersContext";

/* A simple widget that renders out links to each of your folders, with paper counts.
 * Requires LibraryFoldersContext.
 */
export const LibraryFolderListWidget = () => {
  const { libraryFolders } = useContext(LibraryFoldersContext);

  const mkLibraryUrl = (folderId: number) => `https://www.semanticscholar.org/me/library/folder/${folderId}`;

  return (
    <section id="libraryFolderList">
      <h2>Library Folders (Widget 1)</h2>
      <ul>
        {libraryFolders.map((folder) => {
          return (
            <li key={folder.id}>
              <a href={mkLibraryUrl(folder.id)} target="_blank">
                {folder.name} <i>({folder.paperIds.size} paper{folder.paperIds.size === 1 ? "" : "s"})</i>
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

