/*
 * This file defines a context provider component that will
 * make Library Folder data and functionality available to
 * any child.
 *
 * Example usage:
 *
 * ```javascript
 *
 * const FolderCounters = () => {
 *     const { libraryFolders } = useContext(LibraryFoldersContext);
 *     return <span>You have {libraryFolders.length} folders in your library.</span>;
 * }
 *
 * const MyWidget = () => {
 *     return (
 *         <WithLibraryFoldersContext>
 *             <FolderCounters />
 *         </WithLibraryFoldersContext>
 *     );
 * }
 * ```
 *
 * <WithLibraryFoldersContext /> should be used high up enough in the component
 * tree to enclose all components on the page that require access to the data.
 */

import React, { useEffect, useState } from "react";

import S2UserApi, {Folder} from "../api/S2UserApi";

export interface FolderWithPapers {
  id: number;
  name: string;
  paperIds: Set<string>;
}

interface ILibraryFoldersContext {
  libraryFolders: FolderWithPapers[];
  addPaperToFolder: (folderId: number, paperId: string) => Promise<void>;
  removePaperFromFolder: (folderId: number, paperId: string) => Promise<void>;
}

export const LibraryFoldersContext = React.createContext<ILibraryFoldersContext>({
  libraryFolders: [],
  addPaperToFolder: (folderId: number, paperId: string) => new Promise(() => undefined),
  removePaperFromFolder: (folderId: number, paperId: string) => new Promise(() => undefined),
})

export const WithLibraryFoldersContext: React.FC = (props) => {
  const [libraryFolders, setLibraryFolders] = useState<FolderWithPapers[]>([]);
  const [isLoadingFolders, setIsLoadingFolders] = useState(false);

  const bounceFolders = () => {
    /* Someone better at React than me would probably come up with something less
     * hacky. tldr: React uses object ID to detect differences, so state update
     * is a no-op unless you rebuild the object in question.
     */
    const newFolders = libraryFolders.map((folder: FolderWithPapers) => {
      return {
        id: folder.id,
        name: folder.name,
        paperIds: new Set(folder.paperIds)
      };
    });

    setLibraryFolders(newFolders);
  }

  const addPaperToFolder = (folderId: number, paperId: string) => {
    return S2UserApi.addPapersToLibraryFolder(folderId, [paperId]).then(() => {
      const folder = libraryFolders.find((folder: FolderWithPapers) => folder.id === folderId);

      if (folder) {
        folder.paperIds.add(paperId);
      }

      bounceFolders();
    });
  };

  const removePaperFromFolder = (folderId: number, paperId: string) => {
    return S2UserApi.removePaperFromLibraryFolder(folderId, paperId).then(() => {
      const folder = libraryFolders.find((folder: FolderWithPapers) => folder.id === folderId);

      if (folder) {
        folder.paperIds.delete(paperId);
      }

      bounceFolders();
    });
  };

  useEffect(() => {
    setIsLoadingFolders(true)
    S2UserApi.getLibraryFolders().then((folderResponse) => {
      const promises = folderResponse.folders.map((folder: Folder) => {
        return S2UserApi.getLibraryFolderPapers(folder.id).then((folderPapersResponse) => {
          return {
            id: folder.id,
            name: folder.name,
            paperIds: new Set(folderPapersResponse.paperIds)
          };
        })
      });

      Promise.all(promises).then((folders) => {
        setLibraryFolders(folders);
        setIsLoadingFolders(false);
      });
    });
  }, []);

  return (
    <div>
      {isLoadingFolders ? (
        <span>Loading library folders...</span>
      ) : (
        <LibraryFoldersContext.Provider value={{libraryFolders, addPaperToFolder, removePaperFromFolder}}>
          {props.children}
        </LibraryFoldersContext.Provider>
      )}
    </div>
  );
}
