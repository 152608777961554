import React, { useEffect, useState } from "react";

import S2UserApi from "../api/S2UserApi";

const S2_LOGIN_WITH_REDIRECT_URL = "https://www.semanticscholar.org/sign-in";

/*
 * This component can be used to wrap any collection of components
 * that first require you to be logged-in to S2.
 * It queries S2's API to determine if you have a valid session;
 * if not, it implements a simpler user flow to log you in and
 * return to the page you're on.
 *
 * Example usage:
 *
 * ```javascript
 * export const MyComponent = () => {
 *   return (
 *     <S2LoginGate>
 *         <div>You can only see me when you're logged in!</div>
 *     </S2LoginGate>
 *   );
 * }
 * ```
 */
export const S2LoginGate: React.FC = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    S2UserApi.isLoggedIn().then(setIsLoggedIn);
  }, []);

  const logInToS2 = () => {
    const url = `${S2_LOGIN_WITH_REDIRECT_URL}?next=${window.location.href}`;
    window.location.href = url;
  };

  return (
    <div>
      {isLoggedIn ? (
        <div>
          <div>You're logged in to S2! Enjoy.</div>
          {props.children}
        </div>
      ) : (
        <button onClick={logInToS2}>
          Log in to S2 to get started!
        </button>
      )}
    </div>
  );
}
